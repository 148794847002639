<template>
  <v-card class="pa-8" max-width="100%">
    <loading :active.sync="uploaded"></loading>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h3>ตั้งค่าระบบ</h3>

      <v-row class="pa-4">
        <v-col cols="12" md="3"><p class="pt-2">จำนวนโควต้าร้านหลัก</p></v-col>
        <v-col cols="12" md="5">
          <v-text-field
            v-model="OPERATION_PRICE_value"
            label="จำนวนโควต้าร้านหลัก"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn @click="setServicePrice()">บันทึก</v-btn>
        </v-col>
      </v-row>

      <!-- <v-row class="pa-4">
        <v-col cols="12" md="3"><p class="pt-6">เปิดการขายหวยไทย*</p></v-col>
        <v-col cols="12" md="5">
          <v-switch v-model="IS_OPEN_value"></v-switch>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn @click="setSelling()">บันทึก</v-btn>
        </v-col>
      </v-row> -->

      <v-divider></v-divider>
      <v-row class="pa-4">
        <v-col cols="12" md="3"><p class="pt-2">งวดลอตเตอรี่*</p></v-col>
        <v-col cols="12" md="5" sm="9">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-menu
                v-model="menufrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="LOTTERY_DATE_value"
                    label="วันที่ออก"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                    clearable
                    @click:clear="LOTTERY_DATE_value = null"
                    :rules="rules.required"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="LOTTERY_DATE_value"
                  @input="menufrom = false"
                  @click="$refs.menufrom.save(LOTTERY_DATE_value)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn @click="setLotteryDate()">บันทึก</v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <!-- <v-row class="pa-4">
        <v-col cols="12" md="5"
          ><p class="pt-2">ตรวจลอตเตอรี่ที่ถูกรางวัล*</p></v-col
        >
        <v-col cols="12" md="2">
          <v-btn
            v-model="checkPrize"
            style="
              background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
              color: white;
              font-weight: 800;
            "
            >ตรวจลอตเตอรี่</v-btn
          >
        </v-col>
      </v-row> -->
      <v-divider></v-divider>
      <br />
      <!-- <v-row class="pa-4">
        <v-col cols="12">
          <v-card max-width="auto" class="pa-10">
            <h3>อัพโหลดไฟล์</h3>
            <span class="red--text"
              >แนบไฟล์ได้แค่ .csv ขนาดไม่เกิน300mb เท่านั้น</span
            >

            <v-row align="center" justify="center">
              <v-col cols="12" md="8">
                <input
                  type="file"
                  accept=".csv"
                  id="fileUpload"
                  ref="file"
                  v-on:change="handleFileUpload"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-btn
                  class="ma-2"
                  color="primary"
                  style="color: white"
                  v-on:click="submitFile()"
                  >อัพโหลด</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row class="pa-4">
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="submit()" color="primary">บันทึก</v-btn> -->
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      lazy: false,
      uploaded: false,
      file: "",
      type: "csv",
      checkPrize: "",
      OPERATION_PRICE_id: "",
      OPERATION_PRICE_value: "",
      LOTTERY_DATE_id: "",
      LOTTERY_DATE_value: "",
      POWERBALL_COMISSION_PRICE_value: "",
      LAO_COMISSION_PRICE_value: "",
      LAO_DATE_value: "",
      POWERBALL_DRAW_DATE: "",
      POWERBALL_AUS_DRAW_DATE: "",
      POWERBALL_MEGA_DRAW_DATE: "",
      POWERBALL_EURO_DRAW_DATE: "",
      POWERBALL_JAPAN_DRAW_DATE: "",
      menufrom: false,
      IS_OPEN_id: "",
      IS_OPEN_value: true,
      IS_OPEN_POWERBALL_value: true,
      IS_OPEN_LAO_value: true,
      IS_OPEN_BLUE_value: true,
      IS_OPEN_MEGA_value: true,
      IS_OPEN_EURO_value: true,
      IS_OPEN_JAPAN_value: true,
      CONVERT_USD: 0,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      configName: "",
      checkValidate: true,
      lazy: false,
      user: "",
      // configName: "OPERATION_PRICE"
      // createdAt: "2021-07-21T16:19:17.000Z"
      // id: 1
      // type: "OPERATION_PRICE"
      // updatedAt: "2021-07-21T16:19:20.000Z"
      // value1: "20"
      // value2: null
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cancel() {
      this.$router.push("Dashboard");
    },
    async getData() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const OPERATION_PRICE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/MAIN_QUOTA`,
        auth
      );
      const LOTTERY_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/LOTTERY_DATE`,
        auth
      );
      const IS_OPEN = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN`,
        auth
      );
      const IS_OPEN_POWERBALL = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_POWERBALL`,
        auth
      );
      const IS_OPEN_LAO = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_LAO`,
        auth
      );
      const IS_OPEN_BLUE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_BLUE`,
        auth
      );
      const IS_OPEN_MEGA = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_MEGA`,
        auth
      );
      const IS_OPEN_EURO = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_EURO`,
        auth
      );
      const IS_OPEN_JAPAN = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN_JAPAN`,
        auth
      );
      this.IS_OPEN_POWERBALL_value =
        IS_OPEN_POWERBALL.data.data.value1 == "1" ? true : false;
      this.IS_OPEN_LAO_value =
        IS_OPEN_LAO.data.data.value1 == "1" ? true : false;
      this.IS_OPEN_BLUE_value =
        IS_OPEN_BLUE.data.data.value1 == "1" ? true : false;
      this.IS_OPEN_MEGA_value =
        IS_OPEN_MEGA.data.data.value1 == "1" ? true : false;
      this.IS_OPEN_EURO_value =
        IS_OPEN_EURO.data.data.value1 == "1" ? true : false;
      this.IS_OPEN_JAPAN_value =
        IS_OPEN_JAPAN.data.data.value1 == "1" ? true : false;

      const POWERBALL_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_DRAW_DATE`,
        auth
      );
      this.POWERBALL_DRAW_DATE = POWERBALL_DRAW_DATE.data.data.value1;

      const POWERBALL_AUS_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_AUS_DRAW_DATE`,
        auth
      );
      this.POWERBALL_AUS_DRAW_DATE = POWERBALL_AUS_DRAW_DATE.data.data.value1;

      const POWERBALL_MEGA_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_MEGA_DRAW_DATE`,
        auth
      );
      this.POWERBALL_MEGA_DRAW_DATE = POWERBALL_MEGA_DRAW_DATE.data.data.value1;

      const POWERBALL_EURO_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_EURO_DRAW_DATE`,
        auth
      );
      this.POWERBALL_EURO_DRAW_DATE = POWERBALL_EURO_DRAW_DATE.data.data.value1;

      const POWERBALL_JAPAN_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_JAPAN_DRAW_DATE`,
        auth
      );
      this.POWERBALL_JAPAN_DRAW_DATE =
        POWERBALL_JAPAN_DRAW_DATE.data.data.value1;

      const LAO_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/LAO_DATE`,
        auth
      );

      this.LAO_DATE_value = LAO_DATE.data.data.value1;

      const POWERBALL_COMISSION_PRICE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_COMISSION_PRICE`,
        auth
      );

      this.POWERBALL_COMISSION_PRICE_value =
        POWERBALL_COMISSION_PRICE.data.data.value1;

      const LAO_COMISSION_PRICE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/LAO_COMISSION_PRICE`,
        auth
      );

      this.LAO_COMISSION_PRICE_value = LAO_COMISSION_PRICE.data.data.value1;

      console.log("OPERATION_PRICE", OPERATION_PRICE.data.data);
      console.log("LOTTERY_DATE", LOTTERY_DATE.data.data);
      console.log("IS_OPEN", IS_OPEN.data.data);
      this.OPERATION_PRICE_id = OPERATION_PRICE.data.data.id;
      this.OPERATION_PRICE_value = OPERATION_PRICE.data.data.value1;
      this.LOTTERY_DATE_id = LOTTERY_DATE.data.data.id;
      this.LOTTERY_DATE_value = LOTTERY_DATE.data.data.value1;
      this.IS_OPEN_id = IS_OPEN.data.data.id;
      if (IS_OPEN.data.data.value1 == "0") {
        this.IS_OPEN_value = false;
      }
      if (IS_OPEN.data.data.value1 == "1") {
        this.IS_OPEN_value = true;
      }

      const CONVERT_USD = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/CONVERT_USD`,
        auth
      );
      this.CONVERT_USD = CONVERT_USD.data.data.value1;
    },
    async setServicePrice() {
      if (this.$refs.createForm.validate(true)) {
        // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
        const auth = {
          headers: { Authorization: `Bearer ` },
        };
        const data = {
          value1: this.OPERATION_PRICE_value,
          configName: "MAIN_QUOTA",
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/configs/`,
          data,
          auth
        );
        console.log("createType", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขค่าบริการสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          //   this.$router.push("ManageType");
          await location.reload();
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขค่าบริการไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setLotteryDate() {
      if (this.$refs.createForm.validate(true)) {
        // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
        const auth = {
          headers: { Authorization: `Bearer ` },
        };
        const data = {
          value1: this.LOTTERY_DATE_value,
          configName: "LOTTERY_DATE",
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/configs/`,
          data,
          auth
        );
        console.log("createType", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขงวดลอตเตอรี่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          //   this.$router.push("ManageType");
          await location.reload();
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขงวดลอตเตอรี่ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setPowerballDate() {
      if (this.$refs.createForm.validate(true)) {
        const resCheckDate = await this.axios.get(
          `${process.env.VUE_APP_API}/transaction/checkDate/${this.POWERBALL_DRAW_DATE}`
        );
        console.log(resCheckDate.data.data);
        if (resCheckDate.data.data.is_available) {
          const auth = {
            headers: { Authorization: `Bearer ` },
          };
          const data = {
            value1: this.POWERBALL_DRAW_DATE,
            configName: "POWERBALL_DRAW_DATE",
          };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/configs/`,
            data,
            auth
          );
          console.log("createType", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `แก้ไขสำเร็จ ระบบกำลังดำเนินการสั่งซื้อสำหรับผู้ที่ซื้อหลายงวด`,
              showConfirmButton: false,
              timer: 1500,
            });

            await location.reload();
          } else {
            this.$swal.fire({
              icon: "error",
              text: `แก้ไขไม่สำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            text: `วันที่ไม่ถูกต้อง`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setPowerballDateAus() {
      if (this.$refs.createForm.validate(true)) {
        const resCheckDate = await this.axios.get(
          `${process.env.VUE_APP_API}/transactionBlue/checkDate/${this.POWERBALL_AUS_DRAW_DATE}`
        );
        console.log(resCheckDate.data.data);
        if (resCheckDate.data.data.is_available) {
          const auth = {
            headers: { Authorization: `Bearer ` },
          };
          const data = {
            value1: this.POWERBALL_AUS_DRAW_DATE,
            configName: "POWERBALL_AUS_DRAW_DATE",
          };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/configs/`,
            data,
            auth
          );
          console.log("createType", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `แก้ไขสำเร็จ ระบบกำลังดำเนินการสั่งซื้อสำหรับผู้ที่ซื้อหลายงวด`,
              showConfirmButton: false,
              timer: 1500,
            });

            await location.reload();
          } else {
            this.$swal.fire({
              icon: "error",
              text: `แก้ไขไม่สำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            text: `วันที่ไม่ถูกต้อง`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setPowerballDateMega() {
      if (this.$refs.createForm.validate(true)) {
        const resCheckDate = await this.axios.get(
          `${process.env.VUE_APP_API}/transactionMegaMillions/checkDate/${this.POWERBALL_MEGA_DRAW_DATE}`
        );
        console.log(resCheckDate.data.data);
        if (resCheckDate.data.data.is_available) {
          const auth = {
            headers: { Authorization: `Bearer ` },
          };
          const data = {
            value1: this.POWERBALL_MEGA_DRAW_DATE,
            configName: "POWERBALL_MEGA_DRAW_DATE",
          };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/configs/`,
            data,
            auth
          );
          console.log("createType", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `แก้ไขสำเร็จ ระบบกำลังดำเนินการสั่งซื้อสำหรับผู้ที่ซื้อหลายงวด`,
              showConfirmButton: false,
              timer: 1500,
            });

            await location.reload();
          } else {
            this.$swal.fire({
              icon: "error",
              text: `แก้ไขไม่สำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            text: `วันที่ไม่ถูกต้อง`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setPowerballDateeuro() {
      if (this.$refs.createForm.validate(true)) {
        const resCheckDate = await this.axios.get(
          `${process.env.VUE_APP_API}/transactionEurojackpot/checkDate/${this.POWERBALL_EURO_DRAW_DATE}`
        );
        console.log(resCheckDate.data.data);
        if (resCheckDate.data.data.is_available) {
          const auth = {
            headers: { Authorization: `Bearer ` },
          };
          const data = {
            value1: this.POWERBALL_EURO_DRAW_DATE,
            configName: "POWERBALL_EURO_DRAW_DATE",
          };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/configs/`,
            data,
            auth
          );
          console.log("createType", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `แก้ไขสำเร็จ ระบบกำลังดำเนินการสั่งซื้อสำหรับผู้ที่ซื้อหลายงวด`,
              showConfirmButton: false,
              timer: 1500,
            });

            await location.reload();
          } else {
            this.$swal.fire({
              icon: "error",
              text: `แก้ไขไม่สำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            text: `วันที่ไม่ถูกต้อง`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setPowerballDatejapan() {
      if (this.$refs.createForm.validate(true)) {
        const resCheckDate = await this.axios.get(
          `${process.env.VUE_APP_API}/transactionLotteryjapan/checkDate/${this.POWERBALL_JAPAN_DRAW_DATE}`
        );
        console.log(resCheckDate.data.data);
        if (resCheckDate.data.data.is_available) {
          const auth = {
            headers: { Authorization: `Bearer ` },
          };
          const data = {
            value1: this.POWERBALL_JAPAN_DRAW_DATE,
            configName: "POWERBALL_JAPAN_DRAW_DATE",
          };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/configs/`,
            data,
            auth
          );
          console.log("createType", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `แก้ไขสำเร็จ ระบบกำลังดำเนินการสั่งซื้อสำหรับผู้ที่ซื้อหลายงวด`,
              showConfirmButton: false,
              timer: 1500,
            });

            await location.reload();
          } else {
            this.$swal.fire({
              icon: "error",
              text: `แก้ไขไม่สำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            text: `วันที่ไม่ถูกต้อง`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setLaoDate() {
      if (this.$refs.createForm.validate(true)) {
        // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
        const auth = {
          headers: { Authorization: `Bearer ` },
        };
        const data = {
          value1: this.LAO_DATE_value,
          configName: "LAO_DATE",
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/configs/`,
          data,
          auth
        );
        console.log("createType", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          //   this.$router.push("ManageType");
          await location.reload();
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setPowerballComm() {
      if (this.$refs.createForm.validate(true)) {
        // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
        const auth = {
          headers: { Authorization: `Bearer ` },
        };
        const data = {
          value1: this.POWERBALL_COMISSION_PRICE_value,
          configName: "POWERBALL_COMISSION_PRICE",
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/configs/`,
          data,
          auth
        );
        console.log("createType", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          //   this.$router.push("ManageType");
          await location.reload();
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setLaoComm() {
      if (this.$refs.createForm.validate(true)) {
        // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
        const auth = {
          headers: { Authorization: `Bearer ` },
        };
        const data = {
          value1: this.LAO_COMISSION_PRICE_value,
          configName: "LAO_COMISSION_PRICE",
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/configs/`,
          data,
          auth
        );
        console.log("createType", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          //   this.$router.push("ManageType");
          await location.reload();
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async setSelling() {
      if (this.IS_OPEN_value == true) {
        var aaa = "1";
      }
      if (this.IS_OPEN_value == false) {
        var aaa = "0";
      }
      if (this.$refs.createForm.validate(true)) {
        // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
        const auth = {
          headers: { Authorization: `Bearer ` },
        };
        const data = {
          value1: aaa,
          configName: "IS_OPEN",
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/configs/`,
          data,
          auth
        );
        console.log("createType", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขการขายสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          //   this.$router.push("ManageType");
          await location.reload();
        } else {
          this.$swal.fire({
            icon: "error",
            text: `บันทึกการขายสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async setSellingPowerball() {
      if (this.IS_OPEN_POWERBALL_value == true) {
        var aaa = "1";
      }
      if (this.IS_OPEN_POWERBALL_value == false) {
        var aaa = "0";
      }
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: aaa,
        configName: "IS_OPEN_POWERBALL",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      console.log("createType", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขการขายสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        //   this.$router.push("ManageType");
        await location.reload();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },

    async setSellingPowerballAus() {
      if (this.IS_OPEN_BLUE_value == true) {
        var aaa = "1";
      }
      if (this.IS_OPEN_BLUE_value == false) {
        var aaa = "0";
      }
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: aaa,
        configName: "IS_OPEN_BLUE",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      console.log("createType", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขการขายสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        //   this.$router.push("ManageType");
        await location.reload();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async setSellingPowerballMega() {
      if (this.IS_OPEN_MEGA_value == true) {
        var aaa = "1";
      }
      if (this.IS_OPEN_MEGA_value == false) {
        var aaa = "0";
      }
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: aaa,
        configName: "IS_OPEN_MEGA",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      console.log("createType", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขการขายสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        //   this.$router.push("ManageType");
        await location.reload();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async setSellingPowerballeuro() {
      if (this.IS_OPEN_EURO_value == true) {
        var aaa = "1";
      }
      if (this.IS_OPEN_EURO_value == false) {
        var aaa = "0";
      }
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: aaa,
        configName: "IS_OPEN_EURO",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      console.log("createType", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขการขายสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        //   this.$router.push("ManageType");
        await location.reload();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async setSellingPowerballjapan() {
      if (this.IS_OPEN_JAPAN_value == true) {
        var aaa = "1";
      }
      if (this.IS_OPEN_JAPAN_value == false) {
        var aaa = "0";
      }
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: aaa,
        configName: "IS_OPEN_JAPAN",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      console.log("createType", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขการขายสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        //   this.$router.push("ManageType");
        await location.reload();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async setSellingLAO() {
      if (this.IS_OPEN_LAO_value == true) {
        var aaa = "1";
      }
      if (this.IS_OPEN_LAO_value == false) {
        var aaa = "0";
      }
      // if (this.$refs.createForm.validate(true)) {
      // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: aaa,
        configName: "IS_OPEN_LAO",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      console.log("createType", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขการขายสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        //   this.$router.push("ManageType");
        await location.reload();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async setUSD() {
      // if (this.$refs.createForm.validate(true)) {
      // this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: this.CONVERT_USD,
        configName: "CONVERT_USD",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      console.log("createType", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขการค่าเงินสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        //   this.$router.push("ManageType");
        await location.reload();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    handleFileUpload(event) {
      if (event.target.files[0].size > 1048576 * 300) {
        alert("ไฟล์มีขนาดใหญ่เกินไป");
        document.getElementById("fileUpload").value = "";
        // this.value = "";
      } else {
        this.file = this.$refs.file.files[0];
        console.log("file", file);
        console.log(this.type);
      }
    },

    async submitFile() {
      if (this.$refs.createForm.validate(true)) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("type", this.type);
        console.log("formData", formData);
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        // const auth = {
        //   headers: { Authorization: `Bearer ${this.user.token}` },
        // };
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer` + " " + this.user.token,
          },
        };
        console.log("auth", auth);
        this.uploaded = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API}/upload/formData`,
          formData,
          auth
        );
        // .then(function () {
        console.log("SUCCESS!!");
        console.log("response", response.data);
        this.uploaded = false;
        if (response.data.response_status == "SUCCESS") {
          //   await this.$swal
          //     .fire({
          //       text: "อัพโหลดข้อมูลสำเร็จแล้ว กดเพื่อดูข้อมูล",
          //       icon: "success",
          //       showCancelButton: true,
          //       confirmButtonColor: "#3085d6",
          //       cancelButtonColor: "#d33",
          //       confirmButtonText: "ดูข้อมูล",
          //       cancelButtonText: "ยกเลิก",
          //       reverseButtons: true,
          //     })
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "success",
            text: "อัพโหลดไฟล์สำเร็จ",
          });
          await location.reload();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "error",
            text: "อัพโหลดไฟล์ไม่สำเร็จ",
          });
        }
        // })
        // .catch(function () {
        //   console.log("FAILURE!!");
        // });
      }
    },
  },
};
</script>
